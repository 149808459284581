<template>
  <div>
    <a-form :form="form"
            @submit="handleSubmit"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 12 }"
            :colon="false">
      <a-row>
        <a-col :lg="12"
               :md="12"
               :sm="24">
          <a-form-item label="当前项目"
                       class="default-highlight">
            <a-input :disabled="true"
                     :placeholder="msg.designCode"></a-input>
          </a-form-item>
        </a-col>

        <a-col :lg="12"
               :md="12"
               :sm="24">
          <a-form-item label="谈判次数">
            <a-input v-decorator="[
                'negCount',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]"></a-input>
          </a-form-item>
        </a-col>

        <a-col :lg="24"
               :md="24"
               :sm="24">
          <a-form-item label="工程名称"
                       :label-col="{ span: 4 }"
                       :wrapper-col="{ span: 18 }"
                       class="default-highlight">
            <a-input :disabled="true"
                     :placeholder="msg.projectName" />
          </a-form-item>
        </a-col>

        <a-col :lg="24"
               :md="24"
               :sm="24">
          <a-form-item label="标段名称"
                       :label-col="{ span: 4 }"
                       :wrapper-col="{ span: 18 }">
            <a-input v-decorator="[
                'bidName',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]"></a-input>
          </a-form-item>
        </a-col>

        <a-col :lg="12"
               :md="12"
               :sm="24">
          <a-form-item label="日期"
                       class="date">
            <a-date-picker placeholder="请选择"
                           v-decorator="[
                'negDate',
                { rules: [{ required: true, message: '请选择！' }] },
              ]"
                           style="width: 100%"
                           format="YYYY-MM-DD HH:mm:ss" />
          </a-form-item>
        </a-col>

        <a-col :lg="12"
               :md="12"
               :sm="24">
          <a-form-item label="地点">
            <a-input placeholder="请输入"
                     v-decorator="[
                'negLocation',
                { rules: [{ required: true, message: '请输入！' }] },
              ]" />
          </a-form-item>
        </a-col>

        <a-col :lg="24"
               :md="24"
               :sm="24">
          <a-form-item label="谈判记录"
                       :label-col="{ span: 4 }"
                       :wrapper-col="{ span: 18 }">
            <a-textarea :auto-size="{ minRows: 5 }"
                        v-decorator="[
                'negRecord',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]" />
          </a-form-item>
        </a-col>
        <a-col :lg="24"
               :md="24"
               :sm="24">
          <a-form-item :label-col="{ span: 4 }"
                       :wrapper-col="{ span: 18 }">
            <span slot="label"
                  class="">附件</span>
            <div class="link-list">
              <a-checkbox-group :value="selectedFileList"
                                @change="onSelectFile"
                                v-if="fileList.length !== 0">
                <div class="link"
                     v-for="item in fileList"
                     :key="item.id">
                  <a-checkbox :value="item.id">
                    <a target="_blank"
                       download
                       :href="item.completePath">{{
                      item.name
                    }}</a>
                  </a-checkbox>
                </div>
              </a-checkbox-group>
              <div style="color:#ccc;"
                   v-else-if="fileList.length === 0 && id">
                无
              </div>
              <div style="color:#ccc;"
                   v-else>
                《单一来源采购前置预招标谈判记录表》
              </div>

              <div class="control-bar">
                <FileUpload @uploaded="uploaded">
                  <div class="control">
                    <a-icon type="upload" />
                  </div>
                </FileUpload>

                <div class="control"
                     @click="deleteFile">
                  <a-icon type="delete" />
                </div>
              </div>
            </div>
          </a-form-item>
        </a-col>

        <a-col :lg="12"
               :md="12"
               :sm="24">
          <a-form-item>
            <span class="required"
                  slot="label">谈判委员</span>
            <EmployeeSelector title="选择谈判委员"
                              :single="false"
                              @change="selectedUser"
                              :value="selectedUserList.length ? selectedUserList : []">
              <a-button block
                        style="text-align: left;width:auto;">
                <div v-if="selectedUserList.length">
                  <span v-for="item in selectedUserList"
                        :key="item.id">{{ `${item.name} `}}</span>
                </div>
                <div v-else
                     style="color: #1890ff;">选择</div>
              </a-button>
            </EmployeeSelector>
          </a-form-item>
        </a-col>
      </a-row>
      <div class="center">
        <a-space>
          <a-button @click="$close($route.path)">关闭</a-button>
          <a-button htmlType="submit"
                    type="primary"
                    v-if="!isView">提交</a-button>
        </a-space>
      </div>
    </a-form>
  </div>
</template>

<script>
import EmployeeSelector from "@/components/employee-selector";
import { saveAs } from "file-saver";
import {
  add,
  edit,
  fetchDetail,
  exportFile,
  downloadTemplate,
} from "@/api/epc/purchase";

import { mapGetters } from "vuex";

import moment from "moment";
import FileUpload from "@/components/file-upload";

export default {
  props: {
    msg: {
      type: Object,
      default: () => ({
        pid: "",
        designCode: "",
        projectName: "",
      }),
    },
  },
  components: {
    EmployeeSelector,
    FileUpload,
  },
  data() {
    return {
      stage: "epc_purchase_pre_bid",

      id: "", //当前表单的id
      hid: "", //历史记录id
      isView: false,
      form: this.$form.createForm(this),

      fileList: [],
      selectedFileList: [],

      selectedUserList: [],
    };
  },
  computed: {
    ...mapGetters("setting", ["findSingleDictName"]),
    stageName() {
      return this.findSingleDictName("epc_stage", this.stage);
    },
  },
  mounted() {
    const { query } = this.$route;
    const { id, hid, isView } = query || {};

    this.id = id || "";
    this.hid = hid || "";

    this.isView = Boolean(isView) || false;

    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      fetchDetail({ id: this.id })
        .then((res) => {
          if (!res.id) return;

          this.id = res.id;
          this.selectedUserList = JSON.parse(res.negJury);
          this.form.setFieldsValue({
            negCount: res.negCount,
            bidName: res.bidName,
            negDate: res.negDate,
            negLocation: res.negLocation,
            negRecord: res.negRecord,
          });
          if (res.attachmentList) {
            this.fileList = res.attachmentList;
          }
        })
        .catch();
    },

    exportForm() {
      exportFile({
        id: this.id,
        type: this.stage,
      }).then((blob) => {
        saveAs(blob, `${this.msg.projectName}_${this.stageName}.docx`);
      });
    },
    download() {
      downloadTemplate(this.stage).then((blob) => {
        saveAs(blob, `${this.stageName}模版.docx`);
      });
    },

    selectedUser(value) {
      this.selectedUserList = value;
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // 如果没有id ，则新增
          let negJury = this.selectedUserList.map((item) => {
            return {
              userId: item.userId,
              name: item.name,
            };
          });
          if (!this.id) {
            add({
              ...values,
              pid: this.msg.pid,
              projectName: this.msg.projectName,
              designCode: this.msg.designCode,
              attachments: this.fileList,
              negJury: JSON.stringify(negJury),
              negDate: moment(values.negDate).format("YYYY-MM-DD HH:mm:ss"),
              type: this.stage,
            }).then(() => {
              this.form.resetFields();
              this.fileList = [];
              this.selectedUserList = [];
            });
          } else {
            edit({
              ...values,
              pid: this.msg.pid,
              id: this.id,
              historyId: this.hid,
              projectName: this.msg.projectName,
              designCode: this.msg.designCode,
              attachments: this.fileList,
              negJury: JSON.stringify(negJury),
              negDate: moment(values.negDate).format("YYYY-MM-DD HH:mm:ss"),
              type: this.stage,
            }).then(() => {
              this.getDetail();
            });
          }
        }
      });
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];
    },
    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.fileList.findIndex((file) => file.id === item);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }
      });
      this.selectedFileList = [];
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 20px;
}

.center {
  margin-top: 80px;
}
</style>
