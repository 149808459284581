<template>
  <div>
    <Pane :bottom="0"
          :showRemarks="false">
      <a-tabs v-model="activeKey"
              :tabBarStyle="{
          margin: '0',
        }">
        <a-tab-pane v-for="item in tabList"
                    :key="item.key"
                    :tab="item.name"
                    :disabled="item.disabled"></a-tab-pane>
      </a-tabs>

      <template v-slot:extra>
        <div class="export-download">
          <div class="button"
               @click="exportFile"
               v-if="isView">
            <img class="export"
                 :src="exportImage"
                 alt="" />
            <span>导出文档</span>
          </div>
          <div class="button"
               @click="download">
            <img class="download"
                 :src="downloadImage"
                 alt="" />
            <span>下载模板</span>
          </div>
        </div>
      </template>
    </Pane>

    <div class="container">
      <PreBidding ref="preBidding"
                  v-if="activeKey === '0' && pData.projectName"
                  :msg="pData"></PreBidding>
      <NotPreBidding ref="notPreBidding"
                     v-if="activeKey === '1' && pData.projectName"
                     :msg="pData"></NotPreBidding>
    </div>
  </div>
</template>

<script>
import PreBidding from "./components/pre-bidding";
import NotPreBidding from "./components/not-pre-bidding";

import { fetchDetail } from "@/api/epc";

export default {
  name: "purchaseSing",
  components: {
    PreBidding,
    NotPreBidding,
  },
  data() {
    return {
      exportImage: require("@/assets/epc/export.png"),
      downloadImage: require("@/assets/epc/download.png"),

      activeKey: "0",
      tabList: [
        { key: "0", name: "前置预招标", disabled: false },
        { key: "1", name: "不满足前置预招标", disabled: false },
      ],

      form: this.$form.createForm(this),
      pid: "", // 项目id
      pData: {},

      isView: false,
    };
  },
  mounted() {
    const { query } = this.$route;
    const { activeKey, pid, id, isView } = query || {}; // 通过activeKey判断当前是哪个tab，id判断是否是编辑页面，isView判断是否是查看页面，

    if (!pid) {
      return;
    }
    this.activeKey = activeKey || "0";
    this.pid = pid;
    this.isView = Boolean(isView) || false;

    if (id) {
      this.setTab();
    }
    this.getProjectInfo();
  },
  methods: {
    setTab() {
      // 从编辑进入页面，设置非活跃状态的tab不可用
      this.tabList.forEach((item) => {
        if (item.key !== this.activeKey) {
          item.disabled = true;
        }
      });
    },
    getProjectInfo() {
      fetchDetail(this.pid).then((res) => {
        this.pData = {
          pid: this.pid,
          designCode: res.designCode,
          projectName: res.name,
        };
      });
    },

    download() {
      switch (this.activeKey) {
        case "0":
          this.$refs.masterPlan.download();
          break;
        case "1":
          this.$refs.planAdjust.download();
          break;
      }
    },
    exportFile() {
      switch (this.activeKey) {
        case "0":
          this.$refs.masterPlan.exportForm();
          break;
        case "1":
          this.$refs.planAdjust.exportForm();
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 20px;
}

.center {
  margin-top: 80px;
}
</style>
